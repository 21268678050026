<template>
  <v-card flat class="ma-0 pa-0">
    <v-card-text>
      <div class="d-sm-flex align-center">
        <div>
          <h3 class="title font-weight-regular">{{ $t('Revenue.DailyRevenue') }}</h3>
          <h6 class="subtitle-2 font-weight-light">
            {{ $t('Revenue.DailyRevenue7Days') }}
          </h6>
        </div>
        <v-spacer></v-spacer>
        <div class="ml-auto">
          <div class="d-flex align-center px-2">
            <span class="info--text">
              <span class="overline">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular"
                >{{ $t('Revenue.Revenue') }}: <b>{{ totalRevenueString }}</b></span
              >
            </span>
            <br />
            <span class="green--text">
              <span class="overline">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular"
                >{{ $t('Patient.Patient') }}: <b>{{ totalPatientString }}</b></span
              >
            </span>
          </div>
        </div>
      </div>
      <div>
        <vue-apex-charts
          type="area"
          height="308px"
          :options="chart.options"
          :series="chart.series"
        ></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import ReportService from "../../services/pas-report";
import { convertPriceString, awaitAll } from "../../plugins/helper";

export default {
  data: () => ({
    totalRevenueString: "",
    totalPatientString: "",
    chart: {
      series: [
        {
          name: "Revenue",
          data: [],
        },
        {
          name: "Patients",
          data: [],
        },
      ],
      colors: ["#1e88e5", "red"],
      options: {
        grid: {
          show: true,
          borderColor: "rgba(0,0,0,.4)",
          strokeDashArray: 2,
        },
        plotOptions: {},
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: true,
            opacityFrom: 0.8,
            opacityTo: 0.5,
            stops: [0, 90, 100],
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return convertPriceString(value).replace("VND", "").trim();
          },
        },
        markers: {
          size: 0,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            moment().add(-6, "days").format("MMM Do YY"),
            moment().add(-5, "days").format("MMM Do YY"),
            moment().add(-4, "days").format("MMM Do YY"),
            moment().add(-3, "days").format("MMM Do YY"),
            moment().add(-2, "days").format("MMM Do YY"),
            moment().add(-1, "days").format("MMM Do YY"),
            `Today, ${moment().format("MMM Do YY")}`,
          ],
          labels: {},
        },
        yaxis: [
          {
            show: true,
            labels: {
              formatter: (value) => {
                return convertPriceString(value).replace("VND", "").trim();
              },
            },
          },
        ],
        stroke: {
          width: 4,
        },
        tooltip: {
          theme: "dark",
        },
      },
    },
  }),
  created() {
    this.render();
  },
  components: {
    VueApexCharts,
  },
  methods: {
    async render() {
      var end = moment().format("YYYY-MM-DD");
      var start = moment().add(-6, "days").format("YYYY-MM-DD");
      var promises = [
        this.getRevenues(start, end),
        this.getPatientCounts(start, end),
      ];
      var series = await awaitAll(promises);
      this.chart.series = [
        {
          name: "Revenue",
          data: series[0],
        },
        {
          name: "Patients",
          data: series[1],
        },
      ];
      this.$forceUpdate();
    },
    async getRevenues(start, end) {
      var result = await ReportService.getDailyRevenues(start, end);
      if (!result)
        this.$emit("onError", "Failed to load daily revenue statistics!");
      result = result.map((i) => i || 0);
      this.totalRevenueString = convertPriceString(
        result.reduce((a, b) => a + b, 0)
      );
      return result;
    },
    async getPatientCounts(start, end) {
      var result = await ReportService.getDailyPatientCounts(start, end);
      if (!result)
        this.$emit(
          "onError",
          "Failed to load daily patient counts statistics!"
        );
      result = result.map((i) => i || 0);
      this.totalPatientString = result.reduce((a, b) => a + b, 0);
      return result;
    },
  },
};
</script>